$(document).on('turbolinks:load', function() {
  var $win = $(window);
  var $header = $("header");
  var scroll = false;
  var headerHeight = 60;
  var prevHeaderUp;

  setInterval(function() {
    if (scroll) {
      checkHeader();
      scroll = false;
    }
  }, 100);
  checkHeader();

  $win.scroll(function() {
    scroll = true;
  });

  function checkHeader() {
    var scrollTop = $win.scrollTop();
    var isHeaderUp = scrollTop > headerHeight;

    if (prevHeaderUp != isHeaderUp) {
      if (isHeaderUp) {
        $header.addClass('is-header-down');
        $header.removeClass('is-header-up');
      } else {
        $header.addClass('is-header-up');
        $header.removeClass('is-header-down');
      }
    }
    prevHeaderUp = isHeaderUp;
  }
});
