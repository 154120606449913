$(document).on('turbolinks:load', function() {
  $('.sort-button-mobile').click(function(){
    $('.categories-search-filters').toggleClass('show-sort-menu');
    $('body').toggleClass('hide-overflow');
  });

  $('.close-button').click(function(){
    $('.categories-search-filters').toggleClass('show-sort-menu');
    $('body').toggleClass('hide-overflow');
  });

  $('.show-results-button').click(function(){
    $('.categories-search-filters').toggleClass('show-sort-menu');
    $('body').toggleClass('hide-overflow');
  });

  $(window).resize(function() {
    clearTimeout(window.resizedFinished);
    window.resizedFinished = setTimeout(function(){
      if(window.outerWidth > 768){
        $('body').removeClass('hide-overflow');
      } else {
        if($('.show-sort-menu').length > 0){
          $('body').addClass('hide-overflow');
        }
      }
    }, 250);
  });
});
