$(document).on('turbolinks:load', function() {
  if($('#news-letter-container').length > 0) {
    var $win = $(window);
    var scroll = false;
    
    setInterval(function() {
      if (scroll) {
        checkFooter();
        scroll = false;
      }
    }, 100);
    checkFooter();

    $win.scroll(function() {
      scroll = true;
    });


    function checkFooter() {
      if($('#news-letter-container').length > 0) {
        var scrollBottom = $win.scrollTop() + $win.height()
        var isFooterVisible = scrollBottom > ($('#news-letter-container').position().top + 100);

        // Avoid playing when not visible (mobile)
        if($('.news-letter-video').css('display') != 'none'){
          if (isFooterVisible && $('#footer-video')[0].paused && !$('#footer-video')[0].ended) {
            $('#footer-video')[0].play();
          }
        }
      }
    }
  }
});
