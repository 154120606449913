$(document).on('turbolinks:load', function() {
  if(!$('.slides').length) return;
  var slideshow = $('.slides');
  var slides = $('.slide');
  var max = slideshow.data('max');

  slides.hide();
  slideshow.find('.slide[data-index="'+ slideshow.data('current') + '"]').css('display', 'flex');
  slideshow.find('.next').unbind().on('click', function(event){
    var current = slideshow.data('current');
    if (current + 1 >= max){
      slideshow.data('current', 0);
    } else {
      slideshow.data('current', current + 1);
    }
    var next = slideshow.data('current');
    var current_slide = $(slides[current]);
    var vid = current_slide.find("video")
    if(vid.length > 0) {
      vid.get(0).pause()
    }
    var next_slide = $(slides[next]);
    current_slide.hide();
    next_slide.css('display', 'flex');
    next_slide.show();

  });

  slideshow.find('.prev').unbind().on('click', function(event){
    var current = slideshow.data('current');
    if (0 == current){
      slideshow.data('current', max - 1);
    } else {
      slideshow.data('current', current - 1);
    }
    var prev = slideshow.data('current');
    var current_slide = $(slides[current]);
    var vid = current_slide.find("video")
    if(vid.length > 0) {
      vid.get(0).pause()
    }
    var prev_slide = $(slides[prev]);
    current_slide.hide();
    prev_slide.css('display', 'flex');;
    prev_slide.show();
  });
});