$(document).on('turbolinks:load', function() {
  if(!$('#mobile-select-partner').length) return;
  var x, i, j, selElmnt, a, b, c, icon;

  x = document.getElementsByClassName("links-filter");
  for (i = 0; i < x.length; i++) {
    selElmnt = x[i].getElementsByTagName("select")[0];

    a = document.createElement("DIV");
    a.setAttribute("class", "select-selected");
    icon = document.createElement("i");
    icon.setAttribute("class", "toggle-chev fas fa-chevron-down");
    console.log(icon)
    a.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;
    a.appendChild(icon)
    x[i].appendChild(a);

    b = document.createElement("DIV");
    b.setAttribute("class", "select-items select-hide");
    for (j = 1; j < selElmnt.length; j++) {
      c = document.createElement("DIV");
      c.innerHTML = selElmnt.options[j].innerHTML;
      c.setAttribute("data-url", selElmnt.options[j].value);
      c.addEventListener("click", function(e) {
          window.location.replace($(this).data("url"))
      });
      b.appendChild(c);
    }
    x[i].appendChild(b);
    a.addEventListener("click", function(e) {
      e.stopPropagation();
      icon = document.getElementsByClassName("toggle-chev")[0];
      if(icon.classList.contains("fa-chevron-down")) {
        icon.classList.remove("fa-chevron-down");
        icon.classList.add("fa-chevron-up");
      }
      closeAllSelect(this);

      this.nextSibling.classList.toggle("select-hide");
      this.classList.toggle("select-arrow-active");
    });
  }

  function closeAllSelect(elmnt) {
    var x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    icon = document.getElementsByClassName("toggle-chev")[0];
    if(icon.classList.contains("fa-chevron-up")) {
      icon.classList.remove("fa-chevron-up");
      icon.classList.add("fa-chevron-down");
    }
    for (i = 0; i < y.length; i++) {
      if (elmnt == y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
      }
    }
  }
  document.addEventListener("click", closeAllSelect);
});
