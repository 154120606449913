$(document).on('turbolinks:load', function() {
  $('.category-header').click(function() {
    var parent = $(this).parent()
    var chev = $(parent).find(".category-collapse-icon")[0];
    if($(parent).hasClass( "open" )) {
      $(parent).removeClass("open");
      $(parent).addClass("closed");
      $(chev).removeClass("fa-chevron-up");
      $(chev).addClass("fa-chevron-down");
    } else {
      $(parent).addClass("open");
      $(parent).removeClass("closed");
      $(chev).removeClass("fa-chevron-down");
      $(chev).addClass("fa-chevron-up");
    }
  });
});