require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
import Rails from "@rails/ujs";

window.Rails = Rails;
window.jQuery = $;
window.$ = $;

import 'bootstrap/js/dist/modal'
import './slides.js'
import './scroll.js'
import './search.js'
import './mobile_partner.js'
import './mobile_filter.js'
import './header.js'
import './footer.js'
